














































































































































































import { mapActions, mapGetters } from 'vuex';
import rService from '@/services/RequestService/RequestServiceSetup';

export default {
  data() {
    return {
      usersG: 0,
      pwnCountG: 0,
      searchCountG: 0,
    };
  },
  components: {
    Pricing: () => import('@/views/pricing.vue'),
    Video: () => import('@/components/Landing/PopupVideo.vue'),
  },
  computed: {
    ...mapGetters({
      loggedIn: 'loggedIn',
      users: 'socket/users',
      pwnCount: 'socket/pwn_count',
      searchCount: 'socket/search_count',
    }),
  },
  methods: {
    getSource() {
      return 'https://www.youtube.com/embed/7pSv8XqPYE8?iv_load_policy=3&modestbranding=1&playsinline=1&showinfo=0&rel=0&enablejsapi=1&hd=1';
    },
    ...mapActions(['openLogin', 'logout']),
    translate(text) {
      return this.$vuetify.lang.t(`$vuetify.Landing.${text}`);
    },
    async getValues() {
      const { searches, pwnCount, users } = await rService.get('landing_info');
      this.usersG = users;
      this.pwnCountG = pwnCount;
      this.searchCountG = searches;
    },
    formatNumber(x: number) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    },
  },
  mounted() {
    this.getValues();
  },
};
